<template>
  <LayoutView
    :titulo="`Detalle de asignación #${id}`"
    :fondo="asignacion && asignacion ? asignacion.local.imagen : ''"
    :seccionDetalle="`Fotos Finales (Supervisor) `"
  >
    <!-- TABBED ICONS -->
    <form-wizard
      ref="wizard"
      stepSize="sm"
      color="var(--dark)"
      error-color="var(--dark)"
      title=""
      subtitle=""
      back-button-text="Retroceder"
      next-button-text="Siguiente"
      finish-button-text="Actualizar Asignación"
      @on-complete="submitData"
    >
      <!-- FOTOS FINALES -->
      <tab-content
        title="Fotos Finales"
        :before-change="() => validateStep('step1')"
      >
        <PrimerTab
          ref="step1"
          :asignacion="asignacion"
          @on-validate="mergeData"
        ></PrimerTab>
      </tab-content>
    </form-wizard>
  </LayoutView>
</template>

<script>
import { mapState } from "vuex";
import { estados } from "../../../../.env";

import GetAsignaciones from "@/apollo/queries/asignaciones/GetAsignaciones.gql";
import CreateImage from "@/apollo/mutations/imagenes/CreateImage.gql";
import UpdateReconocimientoLocal from "@/apollo/mutations/asignaciones/UpdateReconocimientoLocal.gql";
import LayoutView from "@/layouts/detalles.vue";

import estadoMixins from "@/mixins/estadoMixins.js";
import baseReconocimiento from "@/mixins/baseReconocimiento.js";

// TABS
import PrimerTab from "@/components/asignaciones/novenaVista/primerTab.vue";

export default {
  components: { LayoutView, PrimerTab },
  mixins: [estadoMixins, baseReconocimiento],
  data() {
    return {
      id: this.$route.params.id,
      estados: estados,
      searchingUbication: false,
      ubicationError: false,
      ubicationErrorMessage: "",
      asignacion: null,
      loading: false,
    };
  },

  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  },
  methods: {
    submitData() {
      this.loading = true;

      // PRIMERO SUBE LAS IMAGENES AL SERVIDOR
      this.privateUploadImages().then(() => {
        this.updateReconocimientoLocal(this.asignacion, this.id).then(
          async (res) => {
            // SI HAY ERROR
            if (res.errors) {
              this.loading = false;

              const h = this.$createElement;

              const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                h("b-spinner", { props: { type: "grow", small: true } }),
                ` La asignación no se ha podido actualizar, intentelo nuevamente `,
                h("b-spinner", { props: { type: "grow", small: true } }),
              ]);
              // Create the title
              const vNodesTitle = h(
                "div",
                {
                  class: [
                    "d-flex",
                    "flex-grow-1",
                    "align-items-baseline",
                    "mr-2",
                  ],
                },
                [h("strong", { class: "mr-2" }, "Asignación no actualizada")]
              );

              this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                variant: "danger",
              });
            }
            // SI TODO SALE BIEN EN GENERAL
            // DEBEMOS MANDARLO A LA TERCERA VISTA
            else {
              this.loading = false;
              this.$toast.success(
                `Asignación #${this.id} actualizada, puede descargar el PDF.`,
                {
                  duration: 5000,
                  position: "top-right",
                }
              );
              await this.privateActualizarEstadoAsignacion(11);

              this.$router.push({
                name: "asignaciones",
              });
            }
          }
        );
      });
    },
    init() {
      this.$apollo
        .query({
          query: GetAsignaciones,
          variables: {
            codigoTicket: this.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.asignacion = response.data.GetAsignaciones;
        });
    },

    mergeData(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.asignacion = Object.assign({}, this.asignacion, model);
      }
    },
    validateStep(name) {
      var refToValidate = this.$refs[name];
      return refToValidate.validate();
    },

    // SUBE LAS IMAGENES INICIALES
    privateUploadImages() {
      return new Promise((resolveFunction) => {
        let promesas = [];

        this.asignacion.fotosFin.forEach((item, index) => {
          let promesa = new Promise((responseConjunto) => {
            this.$apollo
              .mutate({
                mutation: CreateImage,
                errorPolicy: "all",
                variables: {
                  imagen: item.imagen,
                  nombre: item.descripcion,
                },
              })
              .then((resImage) => {
                if (resImage.errors) {
                  this.loading = false;
                  const h = this.$createElement;

                  const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
                    h("b-spinner", { props: { type: "grow", small: true } }),
                    ` La asignación no se ha podido actualizar, intentelo nuevamente `,
                    h("b-spinner", { props: { type: "grow", small: true } }),
                  ]);
                  // Create the title
                  const vNodesTitle = h(
                    "div",
                    {
                      class: [
                        "d-flex",
                        "flex-grow-1",
                        "align-items-baseline",
                        "mr-2",
                      ],
                    },
                    [
                      h(
                        "strong",
                        { class: "mr-2" },
                        "Asignación no actualizada"
                      ),
                    ]
                  );

                  this.$bvToast.toast([vNodesMsg], {
                    title: [vNodesTitle],
                    solid: true,
                    variant: "danger",
                  });
                  return;
                }

                // SI LO SUBE CORRECTAMENTE
                this.asignacion.fotosFin[index] = resImage.data.CreateImage.id;
                responseConjunto();
              });
          });

          promesas.push(promesa);
        });

        Promise.all(promesas).then(() => resolveFunction());
      });
    },

    geolocate() {
      if (this.datosUsuarioLogueado.tipoUsuario === 2) {
        this.searchingUbication = true;
        this.ubicationError = false;

        return new Promise((resolve) => {
          navigator.geolocation.getCurrentPosition(
            this.setGeolocalizacion,
            this.errorUbicationFunction
          );

          resolve();
          return true;
        });
      } else {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Debe esperar a que el supervisor finalice el reconocimiento del local. `,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);

        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "No puede continuar")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
        return false;
      }
    },
    setGeolocalizacion(position) {
      this.asignacion.latitud = position.coords.latitude;
      this.asignacion.longitud = position.coords.longitude;

      this.searchingUbication = false;
      this.$refs.wizard.changeTab(0, 1);
    },
    errorUbicationFunction() {
      const h = this.$createElement;

      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        h("b-spinner", { props: { type: "grow", small: true } }),
        ` Debes permitir la ubicación, intentelo nuevamente, brinde los permisos. `,
        h("b-spinner", { props: { type: "grow", small: true } }),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        {
          class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
        },
        [h("strong", { class: "mr-2" }, "Ubicación no brindada")]
      );

      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        solid: true,
        variant: "danger",
      });

      this.searchingUbication = false;
    },
  },
};
</script>
