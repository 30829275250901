<template>
  <div class="row">
    <!-- EN CASO DE NO HABER IMAGENES FINALES -->
    <!-- ASIGNACION DE IMAGENES FINALES -->
    <!-- Solo los supervisores pueden asignar imágenes -->
    <div class="col-md-12">
      <section class="col" v-if="datosUsuarioLogueado.tipoUsuario === 2">
        <p class="h5 detalles-asignacion__sub font-weight-bold my-0">
          Asignar imágenes finales
        </p>

        <section class="row mt-3">
          <div class="col">
            <vue-dropzone
              ref="dropzoneImage"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-complete="setImageURLPreview"
            ></vue-dropzone>
          </div>
        </section>

        <section
          class="row galeria-previa mt-3"
          v-if="datosUsuarioLogueado.tipoUsuario === 2"
        >
          <div
            class="col-12 col-sm-6 col-md-4 col-md-3 position-relative"
            v-for="(imageUploaded, index) in fotosFin"
            :key="index"
          >
            <span
              class="galeria-previa__icono d-flex justify-content-center align-items-center pointer"
              @click="removeSelectedImage(index)"
            >
              <i class="fas fa-trash-alt"></i>
            </span>

            <img
              :src="imageUploaded.urlPreview"
              alt=""
              class="img-fluid galeria-previa__imagen"
            />

            <div class="form-group mt-2">
              <label for="description">Descripción</label>
              <textarea
                id="description"
                class="form-control"
                required
                placeholder="Descripción sobre la imagen"
                v-model="imageUploaded.descripcion"
              ></textarea>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 145,
        thumbnailHeight: 145,
        addRemoveLinks: false,
        acceptedFiles: ".jpg, .jpeg, .png",
        dictDefaultMessage: `<p class='text-default'><i class='fa fa-cloud-upload mr-2'></i> Arrastre o haga click aquí</p>
                              <p class="form-text">Archivos permitidos: .jpg, .jpeg, .png</p>
                            `,
      },
      fotosFin: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
  },
  validations: {
    fotosFin: {
      minLength: minLength(1),
      required,
      $each: {
        imagen: {
          required,
        },
        urlPreview: {
          required,
        },
        descripcion: {
          required,
        },
      },
    },
  },
  methods: {
    setImageURLPreview(file) {
      let archivo = {
        imagen: file,
        urlPreview: URL.createObjectURL(file),
        descripcion: "",
      };

      this.fotosFin.push(archivo);

      this.$refs.dropzoneImage.removeFile(file);
    },
    removeSelectedImage(index) {
      this.fotosFin.splice(index, 1);
    },
    validate() {
      this.$v.$touch();
      var isValid = !this.$v.$invalid;
      this.$emit("on-validate", this.$data, isValid);

      if (!isValid) {
        const h = this.$createElement;

        const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
          h("b-spinner", { props: { type: "grow", small: true } }),
          ` Se debe ingresar fotos del lugar, mínimo una foto, intentelo nuevamente. Tambien llenar la descripción de cada foto.`,
          h("b-spinner", { props: { type: "grow", small: true } }),
        ]);
        // Create the title
        const vNodesTitle = h(
          "div",
          {
            class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"],
          },
          [h("strong", { class: "mr-2" }, "Debe ingresar fotos")]
        );

        this.$bvToast.toast([vNodesMsg], {
          title: [vNodesTitle],
          solid: true,
          variant: "danger",
        });
      }

      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
.galeria-previa {
  &__imagen {
    height: 150px;

    border-radius: 0.3rem;
  }

  &__icono {
    width: 25px;
    height: 25px;

    background-color: white;

    position: absolute;
    top: 0;
    right: 1rem;
  }

  textarea {
    height: 60px !important;
  }
}
</style>
